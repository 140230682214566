import { useCallback, useContext, useEffect, useState } from "react";
import { AuthContext, Token } from "../contexts/authContext";
import { DataRefreshContext } from "../contexts/dataRefreshContext";
import { EnvironmentConfig, EnvironmentContext } from "../contexts/environmentContext";
import { PagedResponse } from "./models";

type ApiGetter<T> = (token: Token, environment: EnvironmentConfig, query?: string) => Promise<T>;

const defaultDependency = {};
const awaitApiCall = async function <T>(
    setState: React.Dispatch<React.SetStateAction<T>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setLastLoadedAt: React.Dispatch<React.SetStateAction<Date | undefined>>,
    value: Promise<T>
) {
    const result = await value;
    setState(result);
    setLoading(false);
    setLastLoadedAt(new Date());
};

export interface ApiState<T> {
    value: T,
    loading: boolean;
    failed: boolean;
    lastLoadedAt?: Date;
    refresh(): Promise<void>;
}

export interface PagedApiState<T> extends ApiState<PagedResponse<T> | undefined> {
    currentPage: number;
    currentPageSize: number;
    goToPage(page: number): void;
    setPageSize(pageSize: number): void;
}

export function useApiState<T>(get: ApiGetter<T>, defaultValue: T, dependency?: any): ApiState<T> {
    const [value, set] = useState<T>(defaultValue);
    const [loading, setLoading] = useState(false);
    const [failed, setFailed] = useState(false);

    const [lastLoadedAt, setLastLoadedAt] = useState<Date | undefined>();
    const authContext = useContext(AuthContext);
    const environmentContext = useContext(EnvironmentContext);
    const dataRefreshContext = useContext(DataRefreshContext);

    if (!authContext.currentUser) {
        throw new Error('useApiState should not be called before the user is authenticated');
    }

    const dependencyList = dependency
        ? (Array.isArray(dependency) ? dependency : [dependency])
        : [defaultDependency];

    dependencyList.push(dataRefreshContext.refreshCounter);

    const refresh = useCallback(async () => {
        setLoading(true);
        setFailed(false);
        try {
            const result = await get(authContext.currentUser!.accessToken, environmentContext);
            set(result);
            setLastLoadedAt(new Date());
        }
        catch(e){
            setFailed(true);
        }
        finally {
            setLoading(false);
        }
    }, [get, set, setLoading, authContext, environmentContext]);

    useEffect(() => {
        refresh();
    }, [...dependencyList, refresh]);

    return { value, loading, failed, lastLoadedAt, refresh };
};

export function usePagedApiState<T>(get: ApiGetter<PagedResponse<T>>, dependency?: any): PagedApiState<T> {
    const [currentPage, setCurrentPage] = useState(0);
    const [totalItems, setTotalItems] = useState<number | undefined>(undefined); // To store the total count of items
    const [loading, setLoading] = useState(true); // To track the loading state
    const [allLoaded, setAllLoaded] = useState(false); // To track if all data has been loaded

    const dependencyList = dependency ? (Array.isArray(dependency) ? dependency : [dependency]) : [defaultDependency];
    dependencyList.push(currentPage);

    // Fetch total items count initially and load data
    const loadAllData = useCallback(async (t, e) => {
        try {
            // First fetch to get totalCount
            const initialResponse = await get(t, e, `take=1&skip=0`); // Fetch a single item to get total count
            if (initialResponse?.totalCount) {
                const totalItemCount = initialResponse.totalCount;
                setTotalItems(totalItemCount); // Setting the total count

                // Fetching all data with the total item count
                const fullResponse = await get(t, e, `take=${totalItemCount}&skip=0`);
                if (fullResponse?.items) {
                    setAllLoaded(true);
                    setLoading(false); // Stop loading after all data is fetched
                    return fullResponse;
                }
            } else {
                setLoading(false); 
                setAllLoaded(true);
                return { items: [], totalCount: 0 };
            }
        } catch (error) {
            setLoading(false);
        }
    }, [get]);

    const state = useApiState(loadAllData, undefined, dependencyList);

    // Returning loading state to avoid rendering until data is fetched
    if (loading || !allLoaded) {
        return {
            currentPage,
            currentPageSize: totalItems ?? 0,
            goToPage: (p) => setCurrentPage(p),
            setPageSize: () => {}, 
            loading,
            value: undefined,
            failed: false,
            refresh: async () => {},
            lastLoadedAt: undefined,
        };
    }

    return {
        currentPage,
        currentPageSize: totalItems ?? 0,
        goToPage: (p) => setCurrentPage(p),
        setPageSize: () => {}, 
        ...state,
    };
}

export function useGetAuthorized<T>(url: string, postProcessor?: (t: T) => T, manipulateUrl?: (url: string) => string): ApiGetter<T> {
    return useCallback(async (token, environment, query) => {

        await token.refreshIfExpired();

        let effectiveUrl = url;
        if (query) {
            effectiveUrl = effectiveUrl + (effectiveUrl.indexOf('?') >= 0 ? '&' : '?') + query;
        }

        effectiveUrl = environment.api.baseUrl + 'api/' + effectiveUrl;

        if (manipulateUrl) {
            effectiveUrl = manipulateUrl(effectiveUrl);
        }

        let response = await fetch(effectiveUrl, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + token.jwt,
            })
        });
        let json = await response.json() as T;
        if (postProcessor) {
            json = postProcessor(json);
        }
        return json;
    }, manipulateUrl ? [url, manipulateUrl] : [url]);
}

export interface ApiCall<TInput, TResponse> {
    (input: TInput): Promise<TResponse>;
}

export function useAuthorizedFetch(jsonBody: boolean = true) {
    const authContext = useContext(AuthContext);
    const environmentContext = useContext(EnvironmentContext);
    return useCallback(async (url: string, init?: RequestInit) => {

        const token = authContext.currentUser!.accessToken;
        await token.refreshIfExpired();

        const headers = new Headers({
            ...(init?.headers || {}),
            'Authorization': 'Bearer ' + token.jwt,
            ...(jsonBody ? { 'Content-Type': 'application/json; charset=utf-8' } : {})
        });

        return fetch(environmentContext.api.baseUrl + 'api/' + url, {
            ...(init || {}),
            headers
        });
    }, [authContext, environmentContext, jsonBody]);
}