import { AmplifyLoadingSpinner } from "@aws-amplify/ui-react";
import { Button, Table } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useOrganizationUsers, useUsers } from "../api";
import { User } from "../api/models";
import { PagedApiState } from "../api/useApiState";
import { toAntdPagination } from "../util";
import { Badge } from "./Badge";

interface UserTableProps {
    users: PagedApiState<User>;
    selectedUserIds?: string[];
    setSelectedUserIds?(ids: string[]): void;
    footer?: React.ReactNode;
    hideFooter?: boolean;
}

export function UserTable(props: UserTableProps) {
    const { users, selectedUserIds, setSelectedUserIds } = props;
    const { t } = useTranslation();
    let { hideFooter } = props;
    hideFooter = hideFooter || (!props.footer && !setSelectedUserIds);

    const onChange = (selectedRowKeys: React.Key[]) => {

                const isOnCurrentPage = users.value!.items.reduce((is, b) => {
                    is[b.id] = true;
                    return is;
                }, {} as { [id: string]: boolean });
        
                const selectedUserIdsOnCurrentPage = selectedRowKeys.filter(r => isOnCurrentPage[r]) as string[];
        
                setSelectedUserIds!([
                    ...selectedUserIds!.filter(b => !isOnCurrentPage[b]),
                    ...selectedUserIdsOnCurrentPage
                ]);
            };
    return !users.loading && !users.value
        ? (<AmplifyLoadingSpinner />)
        : (<Table
            sticky
            size="middle"
            dataSource={users.value?.items || []}
            rowSelection={setSelectedUserIds ? {
                type: 'checkbox',
                onChange: onChange,
                selectedRowKeys: selectedUserIds
                } : undefined}
            loading={users.loading}
            rowKey="id"
            columns={[
                {
                    title: t('user:email'),
                    dataIndex: 'email', 
                    sorter: (a: User, b: User) => a.email.localeCompare(b.email),
                },
                {
                    title: t('user:id'),
                    render: (b: User) => b.id
                }
            ]}
            pagination={toAntdPagination(users)}
            footer={hideFooter ? undefined : () =>
                <div style={{ display: 'grid', gridTemplateColumns: 'auto auto auto auto auto auto auto auto 1fr', gridColumnGap: '1em' }}>
                    {selectedUserIds && setSelectedUserIds && <Badge count={selectedUserIds.length} color="gray">
                        <Button
                            disabled={selectedUserIds.length === 0}
                            onClick={() => setSelectedUserIds([])}>
                            {t('organization:clear-selected-users')}
                        </Button>
                    </Badge>}
                    {props.footer}
                </div>}
            data-testid="UsersTable"
        />);
}

interface OrganizationUsersTableProps {
    organizationId: string;
    selectedUserIds?: string[];
    setSelectedUserIds?(ids: string[]): void;
    footer?: React.ReactNode;
    hideFooter?: boolean;
}

export function OrganizationUsersTable(props: OrganizationUsersTableProps) {
    const users = useOrganizationUsers(props.organizationId);
    return <UserTable users={users} {...props} />;
}

interface AllUsersTableProps {
    assignedToOrganization?: boolean;
    selectedUserIds?: string[];
    setSelectedUserIds?(ids: string[]): void;
    footer?: React.ReactNode;
    hideFooter?: boolean;
}

export function AllUsersTable(props: AllUsersTableProps) {
    const users = useUsers(props.assignedToOrganization);
    return <UserTable users={users} {...props} />;
}