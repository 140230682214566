import { useTranslation } from 'react-i18next';
import { Tabs, Table, Tag, Card, Button, Drawer, message } from 'antd';
import { CheckCircleOutlined, CopyOutlined, PlusOutlined, ZoomOutOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { BeaconEnvironment, BeaconPosition, useEnvironmentHistory, usePositionHistory, FromToFilter, useErrorHistory, BeaconError, useActiveErrors, Beacon, useLifecycleHistory, BeaconLifecycle, isOn, isOff, BeaconWithPosition, useErrorTranslation, ErrorSeverity, useBeacon } from '../api';
import { Chart, SeriesDefintion } from './Chart';
import { CSSProperties, useCallback, useContext, useEffect, useState } from 'react';
import { maxBy, toAntdPagination, useSetting } from '../util';
import { BeaconTelemetryFilter, BeaconTelemetryFilterValue } from './BeaconTelemetryFilter';
import { DownloadPositions } from './DownloadPositions';
import { DownloadEnvironmentMessages } from './DownloadEnvironmentMessages';
import { AuthContext } from '../contexts/authContext';
import { BeaconDetailMap } from './BeaconDetailMap';
import { TimeRangeSelection, useTimeRangeSelection } from './TimeRangeSelection';
import { ActiveErrorsLabel } from './BeaconActiveErrorsTooltip';
import { BeaconErrorStatus } from './BeaconErrorStatus';
import { DualAxisLineChart } from './MultiAxisChart';
import './CardUI.css';
import './Map.css';
import './Battery.css';
import { Battery } from './BeaconStatus';
import { CloseOutlined } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';

const computeFrom = (lastLoadedAt: Date | undefined, selectedFromToFilter: FromToFilter) => {
    return typeof (selectedFromToFilter) === 'number' ? new Date((lastLoadedAt || new Date()).getTime() - selectedFromToFilter * 1000) : selectedFromToFilter.from;
}

const computeTo = (lastLoadedAt: Date | undefined, selectedFromToFilter: FromToFilter) => {
    return typeof (selectedFromToFilter) === 'number' ? (lastLoadedAt || new Date()) : selectedFromToFilter.to;
}

function ChartLabel(props: { lastLoadedAt?: Date; from: Date; to: Date; onZoom: (from: Date, to: Date) => void; canZoomOut: boolean }) {
    const { t } = useTranslation();
    const { from, to, onZoom } = props;
    const label = props.lastLoadedAt ? t('last-loaded-at', { at: props.lastLoadedAt.toLocaleString() }) : undefined;

    const onZoomOut = useCallback(() => {
        const duration = to.getTime() - from.getTime();
        onZoom(new Date(from.getTime() - duration / 2), new Date(to.getTime() + duration / 2));
    }, [from, to, onZoom]);

    if (!props.canZoomOut) {
        return <>{label}</>;
    }

    return <span>
        {label && <span style={{ marginRight: '8px', lineHeight: '30.21px' }}>{label}</span>}
        <span className="button-like" onClick={onZoomOut} style={{ padding: '0 5px', aspectRatio: '1', backgroundColor: 'white', cursor: 'pointer', float: 'right' }}>
            <ZoomOutOutlined style={{ color: 'transparent' }} />
        </span>
    </span>;
}

interface BeaconProps {
    constructionProjectId?: string;
    beacon: Beacon;
    breadcrumb: React.ReactElement;
}

export function BatteryOff() {
    return (
        <span style={{ display: 'inline-flex', alignItems: 'center', gap: '8px' }}>
            <span className="battery-container">
                <span className="battery-bump"></span>
                <span className="battery-outer">
                    <span className={'battery-level off'} ></span>
                </span>
            </span>
        </span>
    );
}

export function BeaconDetails(props: BeaconProps) {
    const [drawerLocationVisible, setLocationDrawerVisible] = useState(false);
    const [drawerVoltageVisible, setVoltageDrawerVisible] = useState(false);
    const [drawerMapVisible, setMapDrawerVisible] = useState(false);
    const [drawerMotionDetectionVisible, setMotionDetectionDrawerVisible] = useState(false);
    const [drawerErrorVisible, setErrorDrawerVisible] = useState(false);

    const showLocationDrawer = () => {
        setLocationDrawerVisible(true);
    };

    const closeLocationDrawer = () => {
        setLocationDrawerVisible(false);
    };

    const showVoltageDrawer = () => {
        setVoltageDrawerVisible(true);
    };

    const closeVoltageDrawer = () => {
        setVoltageDrawerVisible(false);
    };

    const showMapDrawer = () => {
        setMapDrawerVisible(true);
    };

    const closeMapDrawer = () => {
        setMapDrawerVisible(false);
    };

    const showMotionDetectionDrawer = () => {
        setMotionDetectionDrawerVisible(true);
    };

    const closeMotionDetectionDrawer = () => {
        setMotionDetectionDrawerVisible(false);
    };

    const showErrorDrawer = () => {
        setErrorDrawerVisible(true);
    };

    const closeErrorDrawer = () => {
        setErrorDrawerVisible(false);
    };

    const [isMapInfoVisible, setIsMapInfoVisible] = useState(false);
    const showMapInfo = () => {
        setIsMapInfoVisible(true);
    };

    const hideMapInfo = () => {
        setIsMapInfoVisible(false);
    };

    const { t } = useTranslation();
    const errorTranslation = useErrorTranslation();

    const { constructionProjectId, beacon } = props;
    const [filter, setFilter] = useSetting<BeaconTelemetryFilterValue>('beacon:telemetry-filter', 'ShowAll');
    const { selectedFromToFilter, fromToFilterOptions, selectedFromToFilterOptionIndex, onSelectFilterOption, onSelectCustomTimeFrame } = useTimeRangeSelection(beacon.id);

    const positionHistory = usePositionHistory(constructionProjectId, beacon.id, selectedFromToFilter);
    const environmentHistory = useEnvironmentHistory(constructionProjectId, beacon.id, selectedFromToFilter);
    const errorHistory = useErrorHistory(beacon.id, selectedFromToFilter);
    const lifecycleHistory = useLifecycleHistory(beacon.id, selectedFromToFilter);
    const activeErrors = useActiveErrors(beacon.id);

    const isSolutionOperator = useContext(AuthContext).currentUser?.accessToken?.hasRole('solution-operator');

    let positions = positionHistory.value || [];
    if (filter === 'GpsOnly') {
        positions = positions.filter(p => p.value.md.pst === 1);
    } else if (filter === 'AccurateCorrectedPosition') {
        positions = positions.filter(p => p.value.md.pst === 3);
    }

    const activeErrorCount = activeErrors?.value?.length || 0;

    const positionSeries: SeriesDefintion<BeaconPosition>[] = [
        { title: t('horizontal-accuracy'), value: p => p.value.md.hAcc },
        { title: t('carrier-phase-range-solution'), value: p => p.value.md.cprs },
        { title: t('vertical-accuracy'), value: p => p.value.md.vAcc },
        { title: t('timestamp-accuracy'), value: p => p.value.md.tAcc },
        { title: t('hdop'), value: p => p.value.md.hDop },
        { title: t('pdop'), value: p => p.value.md.pDop },
        { title: t('vdop'), value: p => p.value.md.vDop },
        { title: t('satellites-used'), value: p => p.value.md.satU },
        { title: t('state-of-position'), value: p => p.value.md.pst },
        { title: t('latitude'), value: p => p.value.pos.lat },
        { title: t('longitude'), value: p => p.value.pos.lon },
        { title: t('altitude'), value: p => p.value.pos.alt },
    ];

    const environmentSeries: SeriesDefintion<BeaconEnvironment>[] = [
        { title: t('battery-voltage'), value: p => p.value.batt.v }
    ];

    const newestPosition = maxBy(positionHistory.value || [], p => p.measuredAt.getTime())?.item;

    const positionFromTo = {
        from: computeFrom(positionHistory.lastLoadedAt, selectedFromToFilter),
        to: computeTo(positionHistory.lastLoadedAt, selectedFromToFilter)
    };

    const environmentFromTo = {
        from: computeFrom(environmentHistory.lastLoadedAt, selectedFromToFilter),
        to: computeTo(environmentHistory.lastLoadedAt, selectedFromToFilter)
    };

    const latestPositionAsFallbackForMap = typeof (selectedFromToFilter) === 'number' ? (beacon as BeaconWithPosition)?.position : undefined;

    const history = useHistory();
    const { beaconId } = useParams<{ beaconId: string }>();
    const beacon_reference = useBeacon(beaconId);
    const ThingIdValid = "Thing Name : "

    const legend = (
        <div>
            <p><span style={{ color: 'green' }}>●</span> ON corrected position</p>
            <p><span style={{ color: 'orange' }}>●</span> ON uncorrected position</p>
            <p><span style={{ color: 'red' }}>●</span> ON error</p>
            <p><span style={{ color: 'grey' }}>●</span> OFF</p>
        </div>
    );
      
    const renderLegend = (style: CSSProperties | undefined) => (
        <div className='beacon-map-info-button-popup' style={style}>
            {legend}
        </div>
    );

    const motionEvents = (positionHistory.value || []).filter(
        (p) => p.reason & (1 << 1) // Filtering for movement-triggered events
    ).map((p) => ({
        measuredAt: new Date(p.measuredAt).toLocaleString(),
        status: 'Motion Detected'
    }));

    const lifecycleEvents = (lifecycleHistory.value?.items || []).map((e) => ({
        measuredAt: new Date(e.measuredAt).toLocaleString(),
        status: isOn(e.value)
            ? 'ON'
            : isOff(e.value)
                ? 'OFF'
                : `Unknown (${e.value})`
    }));

    // combining both types of events i.e. motion detected and lifecycle events
    const allEvents = [...motionEvents, ...lifecycleEvents].sort(
        (a, b) => new Date(a.measuredAt).getTime() - new Date(b.measuredAt).getTime()
    );

    return <div className='screen-with-breadcrumb'>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '3em' }}>
            {props.breadcrumb}
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em' }}>
                {ThingIdValid}
                {beacon_reference?.value ? (
                <>
                {beacon_reference.value.id || "-"}
                <CopyOutlined
                    style={{ marginLeft: '0.5em', cursor: 'pointer' }}
                    onClick={() => {
                        const textToCopy = beacon_reference.value?.id || "-";
                        const tempTextArea = document.createElement("textarea");
                        tempTextArea.value = textToCopy;
                        document.body.appendChild(tempTextArea);
                        tempTextArea.select();
                        document.execCommand("copy");
                        document.body.removeChild(tempTextArea);
                        message.success('Copied to clipboard!');
                    }}
                />
                </>
                ) : (beaconId)}
            </div>
            <TimeRangeSelection
                fromToFilterOptions={fromToFilterOptions}
                selectedFromToFilterOptionIndex={selectedFromToFilterOptionIndex}
                onSelectFilterOption={onSelectFilterOption}
                onSelectCustomTimeFrame={onSelectCustomTimeFrame}
            />
            <BeaconTelemetryFilter filter={filter} onFilterChanged={setFilter} />
        </div>
        <div className="card-container">
            <div className="card">
                <div className="card-header">
                    About this device
                </div>
                <div style={{ height: '250px', overflow: 'auto' }}>
                <div>
                <table className='about-device-card-table'>
                    <tbody>
                        <tr className='about-device-card-row' >
                            <td className='about-device-card-col-a'>
                                Battery Status
                            </td>
                            <td className='about-device-card-col-b'>   
                                {beacon.onOffSwitch?.isOn && !!beacon.environment?.value?.batt && (
                                <div style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '8px' }}>
                                    <Battery environment={beacon.environment} />
                                </div>
                                )}
                                {/* Doesn't matter if the beacon contains an attached voltage or not. The beacon would be displayed as grey if it's turned off. */}
                                {!beacon.onOffSwitch?.isOn && (
                                <div style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '8px' }}>
                                    <BatteryOff/>
                                </div>
                                )}
                            </td>
                        </tr>
                        <tr className='about-device-card-row'>
                            <td className='about-device-card-col-a'>
                                ICCID
                            </td>
                            <td className='about-device-card-col-b'>
                                {beacon_reference.value?.iccid && beacon_reference.value.iccid !== "-" ? (
                                <>
                                {beacon_reference.value.iccid || "-"}
                                {beacon_reference.value.iccid && (
                                <CopyOutlined
                                style={{ marginLeft: '0.5em', cursor: 'pointer' }}
                                onClick={() => {
                                    const textToCopy = beacon?.iccid || "-";
                                    const tempTextArea = document.createElement("textarea");
                                    tempTextArea.value = textToCopy;
                                    document.body.appendChild(tempTextArea);
                                    tempTextArea.select();
                                    document.execCommand("copy");
                                    document.body.removeChild(tempTextArea);
                                    message.success('Copied to clipboard!');
                                }}
                            />)}
                            </>) : (beaconId)}
                            </td>
                        </tr>
                        <tr className='about-device-card-row'>
                            <td className='about-device-card-col-a'>
                                Hardware Version
                            </td>
                            <td className='about-device-card-col-b'>
                                {beacon_reference.value ? (<>{beacon_reference.value.hwv || "-"}
                                </>) : (beaconId)}
                            </td>
                        </tr>
                        <tr className='about-device-card-row'>
                            <td className='about-device-card-col-a'>
                                Firmware Version
                            </td>
                            <td className='about-device-card-col-b'>
                                {beacon_reference.value ? (
                                <>{beacon_reference.value.fwv || "-"}
                                </>) : (beaconId)}
                            </td>
                        </tr>
                        <tr className='about-device-card-row'>
                            <td className='about-device-card-col-a'>
                                Organization
                            </td>
                            <td className='about-device-card-col-b'>
                                {beacon ? (<>
                                {beacon.organizationName && beacon.organizationName !== "-"
                                ? (isSolutionOperator ? (
                                <a href={`/organizations/${beacon.organizationName}`}
                                onClick={e => {
                                    e.preventDefault();
                                    history.push(`/organizations/${beacon_reference.value?.organizationId}`);
                                }}
                                style={{ color: '#000000', textDecoration: 'underline' }}
                                >{beacon.organizationName}</a>) : (
                                    <span style={{ color: '#000000' }}>
                                        {beacon.organizationName}
                                        </span>)
                                ):(
                                <a href="/organizations/">
                                    {beacon.organizationName || "-"}
                                </a>
                                )}</>) : (beacon)}
                            </td>
                        </tr>
                        <tr className='about-device-card-row'>
                            <td className='about-device-card-col-a'>
                                Construction Project
                            </td>
                            <td className='about-device-card-col-b'>
                                {beacon ? (
                                <> {beacon.constructionProjectName && beacon.constructionProjectName !== "-"
                                ? (<a href={`/construction-projects/${beacon.constructionProjectName}`}
                                onClick={e => {
                                    e.preventDefault();
                                    history.push(`/construction-projects/${beacon?.constructionProjectId}`);
                                }}
                                style={{ color: '#000000', textDecoration: 'underline' }}
                                >{beacon.constructionProjectName}</a>) : (
                                <a href="/construction-projects">
                                    {beacon.constructionProjectName || "-"}
                                </a>)}
                                </>) : (beacon)}
                            </td>
                        </tr>
                        <tr className='about-device-card-bottom-row'>
                            <td className='about-device-card-col-a'>
                                Construction Site
                            </td>
                            <td className='about-device-card-col-b'>
                                {beacon ? (
                                <>
                                {beacon.constructionSiteName && beacon.constructionSiteName !== "-"
                                ? (<a href={`/construction-projects/${beacon.constructionProjectName}`}
                                onClick={e => {
                                    e.preventDefault();
                                    history.push(`/construction-projects/${beacon?.constructionProjectId}`);
                                }}
                                style={{ color: '#000000', textDecoration: 'underline' }}
                                >{beacon.constructionSiteName}</a>) : (
                                <a href="/construction-projects">
                                    {beacon.constructionSiteName || "-"}
                                </a>
                                )}
                                </>) : (beacon)}
                            </td>
                        </tr> 
                    </tbody>
                </table>
                </div>
                </div>
            </div>
            <div className="card">
                <div className="card-header">
                    Map
                </div>
                <Tabs style={{ height: '100%' }} className='fullsize-tabs'>
                    <Tabs.TabPane key='map' style={{ height: '100%', position: 'absolute' }}>
                        <BeaconDetailMap
                            loading={positionHistory.loading}
                            lastLoadedAt={positionHistory.lastLoadedAt}
                            beacon={beacon}
                            position={newestPosition ?? latestPositionAsFallbackForMap}
                            activeErrors={activeErrors.value}
                            polyLines={filter === 'LatestOnly' ? [] : [{ line: positions.map(p => ({ lat: p.value.pos.lat, lon: p.value.pos.lon })) }]} 
                        />
                        <div className='beacon-map-card-info-div'>
                            <Button 
                                type="default" 
                                className='info-button'
                                onMouseEnter={showMapInfo} 
                                onMouseLeave={hideMapInfo}
                                icon={<InfoCircleOutlined />} 
                            />
                            <Button 
                                type="primary" 
                                onClick={showMapDrawer} 
                                icon={<PlusOutlined />}>
                            </Button>
                            {isMapInfoVisible && renderLegend({ bottom: '40px' })}
                        </div>
                        <Drawer
                            title={
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Map</span>
                                    <div className='beacon-map-card-info-div'>
                                        <Button
                                            type="text"
                                            className='info-button'
                                            icon={<InfoCircleOutlined />} 
                                            onMouseEnter={showMapInfo} 
                                            onMouseLeave={hideMapInfo}
                                        />
                                        <Button
                                            type="text"
                                            className='close-card-drawer-button'
                                            icon={<CloseOutlined />} 
                                            onClick={closeMapDrawer}
                                        />
                                        {isMapInfoVisible && renderLegend({ top: '60px' })}
                                    </div>
                                </div>
                            }
                            placement="right" 
                            closable={false}
                            onClose={closeMapDrawer}
                            visible={drawerMapVisible}
                            width={1200} 
                        >
                            <BeaconDetailMap
                                loading={positionHistory.loading}
                                lastLoadedAt={positionHistory.lastLoadedAt}
                                beacon={beacon}
                                position={newestPosition ?? latestPositionAsFallbackForMap}
                                activeErrors={activeErrors.value}
                                polyLines={filter === 'LatestOnly' ? [] : [{ line: positions.map(p => ({ lat: p.value.pos.lat, lon: p.value.pos.lon })) }]} />
                        </Drawer>
                    </Tabs.TabPane>
                </Tabs>
            </div>
            <div className="card">
                <Button 
                    className='show-card-drawer-button'
                    type="primary" onClick={showErrorDrawer} icon={<PlusOutlined />}>
                </Button> 
                <div className="card-header">
                    Beacon Error Details
                </div>
                <Tabs style={{ height: '100%' }} className='fullsize-tabs'>
                    <Tabs.TabPane key='err' style={{ height: '100%', position: 'absolute' }}>
                        <Table
                            sticky
                            style={{ gridColumn: '1 / -1' }}
                            dataSource={errorHistory.value?.items || []}
                            rowKey='id'
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: (event) => {
                                    event.stopPropagation();
                                    }
                                };
                            }}
                            size='middle'
                            loading={errorHistory.loading}
                            columns={[
                                {
                                    title: t('error:active-state'),
                                    width: 'auto',
                                    render: (e: BeaconError) => e.value.active
                                    ? <Tag color='red'>{t('error:active-state-active')}</Tag>
                                    : <Tag color='green'>{t('error:active-state-inactive')}</Tag>
                                },
                                {
                                    title: t('Warning'),
                                    width: 'auto',
                                    render: (e: BeaconError) => errorTranslation(e.value.code, e.value.severity, !e.value.active)
                                },
                                {
                                    title: t('error:severity'),
                                    width: 'auto',
                                    render: (e: BeaconError) => e.value.severity === ErrorSeverity.Warning
                                    ? <Tag color="orange">{t('error:severity-warning')}</Tag>
                                    : <Tag color="red">{t('error:severity-error')}</Tag>
                                },
                                {
                                    title: t('error:code'),
                                    width: 'auto',
                                    render: (e: BeaconError) => e.value.code,
                                }
                            ]}
                            pagination={false}>
                        </Table>
                        <Drawer
                            title={
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Beacon Error Details</span>
                                    <Button
                                        type="text"
                                        className='close-card-drawer-button'
                                        icon={<CloseOutlined />} 
                                        onClick={closeErrorDrawer}
                                    />
                                </div>
                            }
                            placement="right" 
                            closable={false}
                            onClose={closeErrorDrawer}
                            visible={drawerErrorVisible}
                            width={1200} 
                        >
                            <div onClick={(e) => e.stopPropagation()} style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gridColumnGap: '1em', gridRowGap: '1em' }}>
                                <Card style={{ display: 'flex', alignItems: 'center' }}>
                                    {activeErrorCount === 0
                                    ? 
                                    <div style={{ textAlign: 'center' }}>
                                    <CheckCircleOutlined style={{ color: 'green', fontSize: '3em' }} />
                                        <div>
                                            {t('error:no-active-errors')}
                                        </div>
                                    </div>
                                    : <div style={{ textAlign: 'center' }}>
                                        <BeaconErrorStatus errors={activeErrors.value} style={{ fontSize: '3em' }} />
                                        <div>
                                            <ActiveErrorsLabel errors={activeErrors.value!} />
                                        </div>
                                    </div>}
                                </Card>
                                {activeErrorCount > 0 && 
                                <Card style={{ display: 'flex', alignItems: 'center' }}>
                                    <div style={{ display: 'grid', gridTemplateColumns: 'auto 1fr', gridColumnGap: '1em', gridRowGap: '1em' }}>
                                        {activeErrors.value!.map(e => <>
                                        <span style={{ fontWeight: 'bold' }}>
                                            {errorTranslation(e.code, e.severity)}
                                        </span>
                                        <span>
                                            {t('error:active-since', { since: e.startedAt.toLocaleString() })}
                                        </span>
                                        </>)}
                                    </div>
                                </Card>}
                            </div>
                            <br/>
                            <div>
                                <Table
                                    sticky
                                    style={{ gridColumn: '1 / -1' }}
                                    dataSource={errorHistory.value?.items || []}
                                    rowKey='id'
                                    onRow={(record, rowIndex) => {
                                        return {
                                            onClick: (event) => {
                                                event.stopPropagation();
                                            }
                                        };
                                    }}
                                    size='middle'
                                    loading={errorHistory.loading}
                                    columns={[
                                        {
                                            title: t('error:active-state'),
                                            width: 'auto',
                                            render: (e: BeaconError) => e.value.active
                                            ? <Tag color='red'>{t('error:active-state-active')}</Tag>
                                            : <Tag color='green'>{t('error:active-state-inactive')}</Tag>
                                        },
                                        {
                                            title: t('error:text'),
                                            width: 'auto',
                                            render: (e: BeaconError) => errorTranslation(e.value.code, e.value.severity, !e.value.active)
                                        },
                                        {
                                            title: t('error:severity'),
                                            width: 'auto',
                                            render: (e: BeaconError) => e.value.severity === ErrorSeverity.Warning
                                            ? <Tag color="orange">{t('error:severity-warning')}</Tag>
                                            : <Tag color="red">{t('error:severity-error')}</Tag>
                                        },
                                        {
                                            title: t('error:measured-at'),
                                            render: (e: BeaconError) => e.measuredAt.toLocaleString()
                                        },
                                        {
                                            title: t('error:code'),
                                            width: 'auto',
                                            render: (e: BeaconError) => e.value.code
                                        },
                                        {
                                            title: t('error:data'),
                                            render: (e: BeaconError) => e.value.data === undefined || e.value.data === null ? '' : JSON.stringify(e.value.data)
                                        }
                                    ]}
                                    pagination={false}>
                                </Table>
                            </div>
                        </Drawer>    
                    </Tabs.TabPane>
                </Tabs>
            </div>
            <div className="card">
                <Button 
                    className='show-card-drawer-button'
                    type="primary" onClick={showMotionDetectionDrawer} icon={<PlusOutlined />}>
                </Button>
                <div className="card-header">
                    Events
                </div>
                <Tabs style={{ height: '100%' }} className='fullsize-tabs'>
                    <Tabs.TabPane key='life' style={{ height: '100%', position: 'absolute' }}>
                        <Table
                            sticky
                            dataSource={allEvents}
                            rowKey="measuredAt"
                            size='middle'
                            loading={lifecycleHistory.loading}
                            columns={[
                                {
                                    title: t('lifecycle:measured-at'),
                                    dataIndex: 'measuredAt',
                                    render: (measuredAt) => measuredAt.toLocaleString()
                                },
                                {
                                    title: t('lifecycle:status'),
                                    dataIndex: 'status',
                                    render: (status) => (
                                        <Tag color={status === 'ON' ? 'green' : status === 'OFF' ? 'black' : status.includes('Unknown') ? 'grey' : 'blue'}>
                                            {status}
                                        </Tag>
                                    ),
                                }
                            ]}
                            pagination={false}>
                        </Table>
                        <Drawer
                            title={
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Events</span>
                                    <Button
                                        type="text"
                                        className='close-card-drawer-button'
                                        icon={<CloseOutlined />} 
                                        onClick={closeMotionDetectionDrawer}
                                    />
                                </div>
                            }
                            placement="right" 
                            closable={false}
                            onClose={closeMotionDetectionDrawer}
                            visible={drawerMotionDetectionVisible}
                            width={1200} 
                            bodyStyle={{ padding: 0 }}
                        >
                            <Table
                                sticky
                                dataSource={allEvents}
                                rowKey="measuredAt"
                                size='middle'
                                loading={lifecycleHistory.loading}
                                columns={[
                                    {
                                        title: t('lifecycle:measured-at'),
                                        dataIndex: 'measuredAt',
                                        render: (measuredAt) => measuredAt.toLocaleString()
                                    },
                                    {
                                        title: t('lifecycle:status'),
                                        dataIndex: 'status',
                                        render: (status) => (
                                            <Tag color={status === 'ON' ? 'green' : status === 'OFF' ? 'black' : status.includes('Unknown') ? 'grey' : 'blue'}>
                                                {status}
                                            </Tag>
                                        ),
                                    }
                                ]}
                                pagination={false}>
                            </Table>
                        </Drawer>
                    </Tabs.TabPane>
                </Tabs>
            </div>
            <div className="card">    
                <div className="card-header">
                    Location Data
                </div>
                <Tabs style={{ height: '100%' }} className='fullsize-tabs'>
                    <Tabs.TabPane key='pos' style={{ height: '100%', position: 'absolute' }}>
                        <ChartLabel from={positionFromTo.from} to={positionFromTo.to} onZoom={onSelectCustomTimeFrame} canZoomOut={typeof (selectedFromToFilter) !== 'number'} />
                        <DualAxisLineChart onZoom={onSelectCustomTimeFrame} items={positions} loading={positionHistory.loading } 
                            series={positionSeries.filter((_, index) => index === 0 || index === 4)}
                            from={positionFromTo.from} to={positionFromTo.to} />
                            {isSolutionOperator && !!positions.length && 
                            <DownloadPositions beaconId={beacon.id} positions={positions} 
                            style={{ position: 'absolute', bottom: 10, right: 40 }} 
                            from={positionFromTo.from} to={positionFromTo.to} />}
                        <Button 
                            className='show-card-drawer-button'
                            type="primary" onClick={showLocationDrawer} icon={<PlusOutlined />}>
                        </Button>
                        <Drawer
                            title={
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Horizontal Accuracy & HDOP Comparison</span>
                                    <Button
                                        type="text"
                                        className='close-card-drawer-button'
                                        icon={<CloseOutlined />} 
                                        onClick={closeLocationDrawer}
                                    />
                                </div>
                            }
                            placement="right" 
                            closable={false}
                            onClose={closeLocationDrawer}
                            visible={drawerLocationVisible}
                            width={1200} 
                        >
                            <ChartLabel from={positionFromTo.from} to={positionFromTo.to} onZoom={onSelectCustomTimeFrame} canZoomOut={typeof (selectedFromToFilter) !== 'number'} />
                                <DualAxisLineChart onZoom={onSelectCustomTimeFrame} items={positions} loading={positionHistory.loading } 
                                    series={positionSeries.filter((_, index) => index === 0 || index === 4)}
                                    from={positionFromTo.from} to={positionFromTo.to} />
                                    {isSolutionOperator && !!positions.length && 
                                    <DownloadPositions beaconId={beacon.id} positions={positions} 
                                    style={{ position: 'absolute', bottom: 10, right: 40 }} 
                                    from={positionFromTo.from} to={positionFromTo.to} />}
                        </Drawer>   
                    </Tabs.TabPane>
                </Tabs> 
            </div>
            <div className="card">
                <div className="card-header">
                    Battery Voltage
                </div>
                <Tabs style={{ height: '100%' }} className='fullsize-tabs'>
                    <Tabs.TabPane key='env' style={{ height: '100%', position: 'absolute' }} >
                        <ChartLabel from={environmentFromTo.from} to={environmentFromTo.to} onZoom={onSelectCustomTimeFrame} canZoomOut={typeof (selectedFromToFilter) !== 'number'} />
                        <Chart chartDual="Single" chartType="Voltage" onZoom={onSelectCustomTimeFrame} items={environmentHistory.value || []} loading={environmentHistory.loading} series={environmentSeries}  from={environmentFromTo.from} to={environmentFromTo.to} />
                        {isSolutionOperator && !!environmentHistory.value?.length && <DownloadEnvironmentMessages beaconId={beacon.id} environmentMessages={environmentHistory.value || []} style={{ position: 'absolute', bottom: 10, right: 40 }} from={environmentFromTo.from} to={environmentFromTo.to} />}   
                        <Button 
                            className='show-card-drawer-button'
                            type="primary" onClick={showVoltageDrawer} icon={<PlusOutlined />}>
                        </Button>
                        <Drawer
                            title={
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <span>Battery Voltage</span>
                                    <Button
                                        type="text"
                                        className='close-card-drawer-button'
                                        icon={<CloseOutlined />} 
                                        onClick={closeVoltageDrawer}
                                    />
                                </div>
                            }
                            placement="right" 
                            closable={false}
                            onClose={closeVoltageDrawer}
                            visible={drawerVoltageVisible}
                            width={1200} 
                        >
                            <ChartLabel from={environmentFromTo.from} to={environmentFromTo.to} onZoom={onSelectCustomTimeFrame} canZoomOut={typeof (selectedFromToFilter) !== 'number'} />
                            <Chart chartDual="Single" chartType="Voltage" onZoom={onSelectCustomTimeFrame} items={environmentHistory.value || []} loading={environmentHistory.loading} series={environmentSeries}  from={environmentFromTo.from} to={environmentFromTo.to} />
                            {isSolutionOperator && !!environmentHistory.value?.length && <DownloadEnvironmentMessages beaconId={beacon.id} environmentMessages={environmentHistory.value || []} style={{ position: 'absolute', bottom: 10, right: 40 }} from={environmentFromTo.from} to={environmentFromTo.to} />}
                        </Drawer>   
                    </Tabs.TabPane>
                </Tabs> 
            </div>
        </div>
    </div>;
}